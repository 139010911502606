<template>

  <div id="page-user-list">

    <vx-card class="mb-base" title="Forecast Overview" subtitle="Bar Graph from 2015 until Current Year.">
      <vue-apex-charts type="bar" height="266" :options="forecast.options" :series="forecast.series" />
    </vx-card>

    <vx-card class="mb-base" title="Customer Overview" :subtitle="`You have ${customerTotal} contacts from ${companyTotal} companies.`">

      <div class="vx-row">

        <div class="vx-col mb-4 w-full md:w-1/6" v-for="cus in customer">
          <div class="company">
            <div class="company-name">{{ displayCompany(cus.company) }}</div>
            <div class="customer-contacts text-primary">{{ cus.customer_contacts }}</div>
          </div>
        </div>

      </div>

    </vx-card>



  </div>

</template>

<script>

import axios from "@/axios";
import VueApexCharts from 'vue-apexcharts'
import {colors} from "../../../../../themeConfig";

export default {
  name: "DashboardIndex",
  components: {
    VueApexCharts,
  },
  data: () => ({
    forecast: {
      options: {
        chart: {
          stacked: true,
          fontFamily: 'Montserrat'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: []
        },
        colors: [],
      },
      series: [],
    },
    customer: [],
  }),
  computed : {
    yearOptions () {
      let year = new Date().getFullYear();
      let options = [];

      for(let i = 2015; i <= parseInt(year); i++){
        options.push(i.toString())
      }

      return options.reverse()
    },
    customerTotal () {
      if (this.customer.length > 0) {
        return this.customer.map(comp => comp.customer_contacts).reduce((total, item) => total += item);
      } else {
        return "";
      }
    },
    companyTotal () {
      if (this.customer.length > 0) {
        return this.customer.length;
      } else {
        return "";
      }
    }
  },
  methods: {
    generate () {
      axios.get(`/sales/forecasts/graph/bar`).then((response) => {
        this.forecast.options = {
          chart: {
            stacked: true,
            fontFamily: 'Montserrat'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: response.data.categories
          },
          colors: Object.values(colors),
        }

        this.forecast.series = response.data.series;
      })
        .catch((error) => { console.log(error) })

      axios.get(`/sales/customer-contacts/overview`).then((response) => {
        this.customer = response.data;
      })
        .catch((error) => { console.log(error) })
    },
    displayCompany (name) {
      return name.split(' ')[0].split('-')[0];
    }
  },
  created() {
    this.generate();
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.con-vs-popup .vs-popup--content {
  overflow: visible;
}

.year-list {
  display: flex;
}
.year {
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.1);
  margin-right: 12px;
}
</style>
